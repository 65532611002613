<template>
  <ConfirmDialog></ConfirmDialog>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Detalle Ventas</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Ventas
          <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
        </div>
      </div>
      <div class="mt-3 lg:mt-0">
        <Button
          label="Nueva Venta"
          v-if="'Venta Crear' in auth.user.permissions"
          class="p-button-outlined mr-2 p-button-lg"
          icon="pi pi-plus"
          v-tooltip.top="'Nueva Venta'"
          @click="nuevaVenta"
        ></Button>
      </div>
    </div>
    <div class="mt-2">
      <DataTable
        ref="ventas"
        :value="ventas"
        dataKey="id"
        :paginator="true"
        :rows="25"
        :filters="buscar"
        :loading="cargando"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 100, 500, 1000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Ventas"
        responsiveLayout="scroll"
        stripedRows
        showGridlines
        selectionMode="single click"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <!-- <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column> -->
        <Column field="id" header="CÓDIGO"> </Column>
        <Column field="nombre_usuario_caja" header="VENDEDOR"> </Column>
        <Column
          field="razon_social"
          header="RAZÓN SOCIAL"
          style="font-weight: bold"
        >
        </Column>
        <Column field="fecha_venta" header="F.VENTA" style="width: 70px">
          <template #body="{ data }">
            {{ formatDateTime(data.fecha_venta) }}
          </template>
        </Column>
        <Column field="nit" header="NIT/CI"> </Column>
        <Column
          field="descuento"
          header="DESC."
          class="text-right"
          style="width: 20px"
        >
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.descuento) }}
          </template>
        </Column>
        <Column
          field="total_cancelar"
          header="T.VENTA"
          class="text-right"
          v-if="'Caja Ver Detalle' in auth.user.permissions"
          style="font-weight: bold"
        >
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.total_cancelar) }}
          </template>
        </Column>
        <Column
          field="calculo_efectivo"
          header="EFECTIVO"
          class="text-right"
          v-if="'Caja Ver Detalle' in auth.user.permissions"
        >
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.calculo_efectivo) }}
          </template>
        </Column>
        <Column
          field="transferencias"
          header="TRANSF."
          class="text-right"
          v-if="'Caja Ver Detalle' in auth.user.permissions"
        >
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.transferencias) }}
          </template>
        </Column>
        <Column
          field="facturado"
          header="ENVIADO"
          class="text-center"
          v-if="'Venta Facturar' in auth.user.permissions"
        >
          <template #body="{ data }">
            <span
              class="product-badge status"
              :class="{
                'status-outofstock': data.facturado === 0,
                'status-instock': data.facturado === 1,
              }"
            >
              {{ textoFacturado(data.facturado) }}
            </span>
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES" style="width: 50px">
          <template #body="slotProps">
            <Button
              icon="pi pi-ellipsis-v"
              class="p-button-icon-only p-button-raised p-button-info"
              v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            ></Button>
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            ></Menu>
          </template>
        </Column>
      </DataTable>
      <Dialog
        v-model:visible="mostrar_qr"
        modal
        header="FACTURA QR"
        :style="{ width: '200px' }"
      >
        <img
          :src="'data:image/png;base64, ' + qr"
          alt="QR"
          :style="{ width: '150px', height: '150px' }"
        />
      </Dialog>
      <Dialog
        v-model:visible="motivoAnulacionDialog"
        modal
        header="MOTIVO DE ANULACIÓN"
        :style="{ width: '400px' }"
      >
        <div class="p-fluid">
          <div class="p-field">
            <label for="motivo_anulacions"
              ><strong>SELECCIONE MOTIVO ANULACI&Oacute;N:</strong></label
            >
            <Dropdown
              id="motivo_anulacions"
              v-model="motivoAnulacionSelected"
              :options="motivo_anulacions"
              optionLabel="descripcion"
              optionValue="codigoClasificador"
              placeholder="Seleccione Motivo Anulación"
            />
          </div>
        </div>
        <div class="flex justify-content-end mt-4">
          <Button
            label="CANCELAR"
            icon="pi pi-times"
            class="p-button-success mr-2 p-button-lg"
            @click="motivoAnulacionDialog = false"
          />
          <Button
            label="ANULAR FACTURA"
            icon="pi pi-trash"
            class="p-button-danger p-button-lg"
            :loading="enviando"
            @click="anularVentaFactura(anularVentaId)"
          />
        </div>
      </Dialog>
    </div>
  </div>
  <VentaFacturar
    :show="ventafacturaDialog"
    :ventaFactura="ventaFactura"
    @closeModal="ocultarDialog"
    @actualizarListado="cargarVentas"
  >
  </VentaFacturar>
  <VentaMetodoPago
    :show="metodoPagoDialog"
    :metodoPago="metodoPago"
    @closeModal="cerrarModalMetodoPago"
    @actualizarListado="cargarVentas"
  >
  </VentaMetodoPago>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import VentaService from "@/service/VentaService";
import VentaFacturar from "@/module/ventas/VentaFacturar.vue";
import { useAuth } from "@/stores";
import VentaMetodoPago from "@/module/ventas/VentaMetodoPago.vue";
export default {
  components: {
    VentaFacturar,
    VentaMetodoPago,
  },
  data() {
    return {
      ventas: [],
      totalRegistros: 0,
      cargando: false,
      buscar: {
        global: { matchMode: FilterMatchMode.CONTAINS, value: null },
      },
      claseEstado: ["status-outofstock", "status-instock", "status-lowstock"],
      qr: "",
      mostrar_qr: false,
      ventaFactura: {},
      ventafacturaDialog: false,
      metodoPagoDialog: false,
      metodoPago: {},
      motivo_anulacions: [],
      motivoAnulacionSelected: {},
      motivoAnulacionDialog: false,
      anularVentaId: null,
      enviando: false,
    };
  },
  auth: null,
  VentaMetodoPago: null,
  created() {
    this.auth = useAuth();
    this.ventaService = new VentaService();
  },
  mounted() {
    this.cargarVentas();
    this.cargarMotivoAnulacion();
  },
  methods: {
    formatDateTime(dateString) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return new Date(dateString).toLocaleString(undefined, options);
    },
    cargarMotivoAnulacion() {
      this.ventaService.motivo_anulacions().then((data) => {
        this.motivo_anulacions = data.motivo_anulacions;
        this.motivoAnulacionSelected = this.motivo_anulacions.length
          ? this.motivo_anulacions[0].codigoClasificador
          : {};
      });
    },
    acciones(datos) {
      return [
        {
          label: "Ver Detalle",
          icon: "pi pi-fw pi-eye",
          disabled:
            "Venta Ver Detalle" in this.auth.user.permissions ? false : true,
          to: `/ventas/${datos.id}/detalle`,
        },
        {
          label: "Descargar PDF",
          disabled:
            "Venta DescargarPDF" in this.auth.user.permissions ? false : true,
          icon: "pi pi-fw pi-file-pdf",
          command: () => this.imprimirVenta(datos.id),
        },
        /* {
          label: "Mostrar Factura",
          disabled: datos.facturado ? false : true,
          visible: false,
          icon: "pi pi-fw pi-file",
          command: () => this.mostrar_factura(datos),
        }, */
        {
          label: "Crear Factura",
          disabled:
            datos.estado == 0
              ? true
              : false || "Venta Facturar" in this.auth.user.permissions
              ? false
              : true,
          icon: "pi pi-fw pi-file",
          visible: datos.facturado == 0 ? true : false,
          command: () => this.ventaFacturar(datos.id),
        },
        {
          label: "Anular Venta",
          icon: "pi pi-fw pi-trash",
          disabled:
            datos.estado == 0
              ? true
              : false || "Venta Anular" in this.auth.user.permissions
              ? false
              : true,
          visible: datos.facturado == 1 ? false : true,
          command: () => this.anularVenta(datos.id),
        },
        {
          label: "Anular Factura",
          icon: "pi pi-fw pi-trash",
          tooltip: "Anular Factura",
          disabled:
            datos.estado == 0
              ? true
              : false || "Venta Anular" in this.auth.user.permissions
              ? false
              : true,
          visible: datos.facturado == 1 ? true : false,
          command: () => this.motivoAnulacionDialogConfirm(datos),
        },
        {
          label: "Cambiar Metodo de Pago",
          icon: "pi pi-fw pi-money-bill",
          disabled: datos.estado == 0 ? true : false,
          command: () => this.ventaMetodoPago(datos),
        },
      ];
    },
    ventaMetodoPago(datos) {
      this.metodoPago = { ...datos };
      this.metodoPagoDialog = true;
    },
    cerrarModalMetodoPago() {
      this.metodoPagoDialog = false;
    },
    mostrar_factura(datos) {
      this.qr = datos.factura.qr;
      this.mostrar_qr = true;
    },
    ventaFacturar(id) {
      // paso todos los datos de la venta a mi componente de ventaFacturar
      this.ventaFactura = this.ventas.find((x) => x.id == id) || {};
      this.ventafacturaDialog = true;
    },
    ocultarDialog() {
      this.ventafacturaDialog = false;
    },
    motivoAnulacionDialogConfirm(datos) {
      this.anularVentaId = datos.id;
      this.motivoAnulacionDialog = true;
      this.enviando = false;
    },
    anularVentaFactura(id) {
      this.enviando = true;
      let datos = {
        id: id,
        motivoAnulacion: this.motivoAnulacionSelected,
      };
      this.ventaService.anularVenta(id, datos).then((response) => {
        if (response.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: response.mensaje,
            life: 3000,
          });
          this.cargarVentas();
          this.motivoAnulacionDialog = false;
          this.enviando = false;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.mensaje,
            life: 3000,
          });
          this.enviando = false;
        }
      });
    },
    anularVenta(id) {
      this.$confirm.require({
        header: "CONFIRMACIÓN",
        message: "¿ESTÁS SEGURO DE ANULAR ESTA VENTA?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Anular",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.ventaService.anularVenta(id).then((response) => {
            if (response.status == 200) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: response.mensaje,
                life: 3000,
              });
              this.cargarVentas();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.mensaje,
                life: 3000,
              });
            }
          });
        },
      });
    },
    nuevaVenta() {
      this.$router.push({ name: "nueva_venta" });
    },
    cargarVentas() {
      this.cargando = true;
      this.ventaService.getAllVentas().then((response) => {
        this.ventas = response.ventas;
        this.cargando = false;
      });
    },
    imprimirVenta(id) {
      this.ventaService
        .imprimirVenta(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    textoFacturado(facturado) {
      if (facturado == 0) {
        return "NO";
      } else {
        return "SI";
      }
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
  },
  watch: {
    ventas() {
      this.totalRegistros = this.ventas.length;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
